import React from 'react';
import Slider from '../components/slider';
import UrunlerMain from '../components/urunlermain';
import HakkimizdaMain from '../components/hakkimizdamain';

function Hakkimizda() {
  return (
    <div>
        <Slider/>
        <HakkimizdaMain/>
        <UrunlerMain/>
    </div>
  );
}

export default Hakkimizda;