import React, { useState, useEffect } from 'react';
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const images = [
  {
    "src": "/slider/slider3.png",
    "link": "/"
  },
  {
    "src": "/slider/slider2.png",
    "link": "/"
  },
  {
    "src": "/slider/slider1.png",
    "link": "/"
  }
];

const SimpleSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  useEffect(() => {
    const intervalId = setInterval(goToNextSlide, 5000); // Her 3 saniyede bir sıradaki fotoğrafa geç
    return () => clearInterval(intervalId); // Temizleme işlevi
  }, [currentIndex]); // Her currentIndex güncellendiğinde bu useEffect tekrar çalışacak

  return (
    <div className="relative w-full h-auto overflow-hidden">
      <button onClick={goToPrevSlide} className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-white rounded-full p-2 focus:outline-none z-10">
       <FaArrowLeft/>
      </button>
      <button onClick={goToNextSlide} className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-white rounded-full p-2 focus:outline-none z-10">
        <FaArrowRight/>
      </button>
      <div className="flex transition-transform duration-600 ease-in-out" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {images.map((image, index) => (
          <img
            key={index}
            src={image.src}
            alt={`Slide ${index + 1}`}
            className="w-full h-auto md:object-cover max-w-full max-h-full min-h-[300px] md:min-h-[500px]"
          />
        ))}
      </div>
    </div>
  );
};

export default SimpleSlider;
