import React from 'react';

function Footer() {
  return (
    <footer className="bg-zinc-900 text-white py-8 text-center w-full">
      <div className="container px-4 flex flex-col md:flex-row justify-around gap-8">
        {/* Hakkımızda */}
        <div className='flex flex-col md:items-start'>
          <h2 className="text-lg font-freeman mb-4">Hakkımızda</h2>
          <div className="flex flex-col space-y-2 items-center mb-4 gap-6 text-center">
            <img src="ozturkkerestebeyaz.png" alt="Logo" className="h-32" />
            <div className='flex flex-row gap-8'>
                <a href="tel:905404535345" className="text-white bg-transparent border-2 w-full justify-center items-center text-center content-center border-white font-freeman px-3 py-1 rounded-xl hover:bg-white hover:text-black">İSMAİL ÖZTÜRK<br /> - <br />0540 453 5345</a>
                <a href="tel:905415353561" className="text-white bg-transparent border-2 w-full justify-center items-center text-center content-center border-white font-freeman px-3 py-1 rounded-xl hover:bg-white hover:text-black">MUSTAFA ÖZTÜRK<br /> - <br />0541 535 3561</a>
            </div>
            
          </div>
        </div>
        {/* Çalışma Saatleri */}
        <div className='flex flex-col md:items-start'>
          <h2 className="text-lg font-freeman mb-4">Çalışma Saatleri</h2>
          <p className='font-freeman'>Hafta İçi</p>
          <p className='font-freeman  border-b-2 border-white'>07:00 - 19:00</p>
          <p className='font-freeman'>Cumartesi</p>
          <p className='font-freeman  border-b-2 border-white'>07:00 - 19:00</p>
          <p className='font-freeman'>Pazar</p>
          <p className='font-freeman text-red-700  border-b-2 border-white'>KAPALI</p>
        </div>
        {/* İletişim */}
        <div className='flex flex-col md:items-start'>
          <h2 className="text-lg font-freeman mb-4">İletişim</h2>
          <div className="flex flex-col space-y-2 text-center">
            <a href="https://wa.me/+905404535345" target='_blank' className="text-white font-freeman border-2 border-green-500 px-3 py-1 rounded-xl hover:bg-green-500 hover:text-black flex-shrink-0">Whatsapp</a>
            <a href="mailto:ozturkkeresteagacsanayi@gmail.com" className="text-white font-freeman border-2 border-red-500 px-3 py-1 rounded-xl hover:bg-red-500 hover:text-black flex-shrink-0">Mail</a>
            <a href="https://www.instagram.com/ozturkkeresteagacsanayi" target='_blank' className="text-white font-freeman border-2 border-purple-500 px-3 py-1 rounded-xl hover:bg-purple-500 hover:text-black flex-shrink-0">İnstagram</a>
            <a href="https://www.facebook.com/ozturkkeresteagacsanayi" target='_blank' className="text-white font-freeman border-2 border-blue-500 px-3 py-1 rounded-xl hover:bg-blue-500 hover:text-black flex-shrink-0">Facebook</a>
            <p className='font-freeman'>Gaziler Mahallesi 600. Sokak No:31 Manisa/Salihli</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;