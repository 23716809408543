import React from 'react';

const Header = () => {
  return (
    <div className="bg-white">
      <div className="flex justify-center items-center py-6 border-b-2 border-green-600">
        <div className="pl-16 flex items-center justify-start flex-1 pl-8 hidden md:flex">
        </div>
        <div className="flex justify-center">
          <a href="/">
            <img src="/ozturkkerestelogo.png" alt="Logo" className="md:h-32 h-24" />
          </a>
        </div>
        <div className="flex-1 hidden md:block"></div> {/* Empty div to keep the logo centered */}
      </div>
    </div>
  );
};

export default Header;
