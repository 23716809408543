import React, { useState } from "react";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header className="bg-white shadow">
      <nav className="container mx-auto flex justify-between items-center p-4 relative h-16">
        <div className="md:hidden flex justify-end w-full">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="mr-4"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              ></path>
            </svg>
          </button>
        </div>

        <ul
          className={`${
            isOpen
              ? "block z-50 bg-white w-full absolute top-16 left-0 p-4 space-y-4"
              : "hidden"
          } md:flex md:space-x-12 justify-center font-semibold items-center md:w-full md:h-full`}
        >
          <li className="group relative flex items-center justify-center h-full px-4 md:px-0">
            <a
              href="/hakkimizda"
              className="text-gray-800 hover:text-green-600 transition duration-300"
            >
              Hakkımızda
            </a>
            <span className="block h-1 bg-green-600 absolute bottom-0 left-0 w-0 group-hover:w-full transition-all duration-300"></span>
          </li>
          <li className="group relative flex items-center justify-center h-full px-4 md:px-0">
            <a
              href="/fotogaleri"
              className="text-gray-800 hover:text-green-600 transition duration-300"
            >
              Galeri
            </a>
            <span className="block h-1 bg-green-600 absolute bottom-0 left-0 w-0 group-hover:w-full transition-all duration-300"></span>
          </li>
          <li className="group relative flex items-center justify-center h-full px-4 md:px-0">
            <a
              href="/iletisim"
              className="text-gray-800 hover:text-green-600 transition duration-300"
            >
              İletişim
            </a>
            <span className="block h-1 bg-green-600 absolute bottom-0 left-0 w-0 group-hover:w-full transition-all duration-300"></span>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Navbar;
